<template>
  <div class="new_product_release_div">
    <div :class="menuShow ? 'right_content' : 'right_content content_all'">
      <div>

      </div>
      <div class="brand_div">
        <company-item-list :pdfList="pdfList" @PDFHandleClick="PDFHandleClick"></company-item-list>
        <div class="clear_both"></div>
        <paging v-if="total > pageSize" :pageSize="pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
      </div>
    </div>
  </div>
</template>
<script>
import { baseUrl } from '../../utils';
import {GetCompanyList, GetCompanyListNew} from '../../api/pdf/api'
import { GetCategoryList } from '../../api/home/api'
import menuList from '../public/menu.vue'
import goodsSearch from '../public/goodsSearch.vue'
import companyItemList from '../public/companyItemList.vue'
import paging from '../public/paging.vue'
import Bus from '../../bus'
export default {
  name: 'CompanyList',
  data() {
    return {
      searchList: {

      },
      fieldType: {
        brandType: '品牌',
        productType: '产品',
        industryType: '工业',
        functionType: '功能'
      },
      baseUrl,
      // 是否展示菜单
      menuShow: true,
      // 菜单列表
      menuDataList: [],
      // 产品总条数
      total: 0,
      // 产品列表
      pdfList: [],
      // 当前页数
      currentPage: 1,
      // 每页条数
      pageSize: 24,
      // brand 品牌筛选
      brandId: 0,
      // 三级条件
      thirdCatId: 0,
      fourCatId: 0,
      // 产品分类
      productType: 0,
      subCatId: 0,
      // 产业分类
      industryType: 0,
      // 搜索文字
      key: '',
      functionId: 0,
      // 筛选条件
      queryData: [],
      // 是否有搜索条件
      searching: false,
      // 条件列表
      tagList: [],
      itemId: 0,
      itemName: 0,
      industryFirstCatId: 0,
      industrySecordCatId: 0
    }
  },
  watch: {
    searching: {
      handler(val) {}
    },
    '$route.query.searchKey'(val){
      console.log("参数是空")
      this.init();
    },
  },
  components: {
    menuList,
    goodsSearch,
    companyItemList,
    paging
  },
  methods: {
    init() {
      let { searching, searchKey, fieldId, fieldName, name, level, type } = this.$route.query;
      if (type == 'functionId') {
          this.functionId = fieldId
      }
      this.searching = searching == 1 ? true : false;
      this.key = searchKey;
      if(fieldId && fieldName) {
        console.log('首页带参数到pdf首页 搜索态')
        if(level === 1) {
          this.pushTagList({
            level,
            key: 'brandId',
            name,
            value: fieldId,
            fieldType: fieldName
          });
          this[type] = parseInt(fieldId)
        } else if(level === 2) {
          this.pushTagList({
            level,
            key: 'subCatId',
            name,
            value: fieldId,
            fieldType: fieldName
          });
          this[type] = parseInt(fieldId)
        } else if(level === 3) {
          this.pushTagList({
            level,
            key: 'thirdCatId',
            name,
            value: fieldId,
            fieldType: fieldName
          });
          this[type] = parseInt(fieldId)
        } else if(level === 4) {
          this.pushTagList({
            level,
            key: 'fourCatId',
            name,
            value: fieldId,
            fieldType: fieldName
          });
          this[type] = parseInt(fieldId)
        } else if(level === 5) {
          this.pushTagList({
            level,
            key: 'industryFirstCatId',
            name,
            value: fieldId,
            fieldType: fieldName
          });
          this[type] = parseInt(fieldId)
        } else if(level === 6) {
          this.pushTagList({
            level,
            key: 'industrySecordCatId',
            name,
            value: fieldId,
            fieldType: fieldName
          });
          this[type] = parseInt(fieldId)
        } else if(level === 10) {
          this.pushTagList({
            level,
            key: 'functionId',
            name,
            value: fieldId,
            fieldType: fieldName
          });
          this[type] = parseInt(fieldId)
        }
      } else if(searching == 1 && searchKey) {
        console.log('其他页搜索到pdf页')
        this.key = searchKey;
      } else {
        console.log('默认pdf')
      }
      this.getCategoryList();
      this.getPDFList();
      window.addEventListener('setItemEvent', (e) => {
        if(e.key === 'searchKey') {
          this.key = e.newValue;
          this.getPDFList();
        }
      })
      console.log('pdf mounted')
      // window.updateSearch = (val) => {
      //   this.key = val;
      //   this.getPDFList();
      // }
      Bus.$on('updateSearch', val => {
        this.key = val;
        this.getPDFList();
      });
    },
    getTagName(item) {
      return this.fieldType[item.fieldType];
    },
    initValue(type = 'all', fieldName) {
      this.currentPage = 1;
      if(type === 'all') {
        this.brandId = 0;
        this.functionId = 0;
        this.subCatId = 0;
        this.thirdCatId = 0;
        this.fourCatId = 0;
        this.industryFirstCatId = 0;
        this.industrySecordCatId = 0;
        // this.key = '';
      } else if(type === 'brandType') {
        this.brandId = 0;
      } else if(type === 'productType') {
        if(fieldName === 'subCatId') {
          this.subCatId = 0;
          this.thirdCatId = 0;
        } else if(fieldName === 'thirdCatId') {
          this.thirdCatId = 0;
        }
        this.fourCatId = 0;
      } else if(type === 'industryType') {
        if(fieldName === 'industryFirstCatId') {
          this.industryFirstCatId = 0;
        }
        this.industrySecordCatId = 0;
      } else if(type === 'fucntionType') {
        this.functionId = 0;
      }

    },
    deleteSearchText() {
      this.key = '';
      window.localStorage &&  window.localStorage.setItem('searchKey', '');
      window.selfEventSm && window.selfEventSm.clearSearchKey && window.selfEventSm.clearSearchKey()
      this.getPDFList();
    },
    deleteTagList(item) {
      let tagList = this.tagList.filter(tag => {
        let {fieldType: type, fieldName, level, key} = item;
        if(type === 'brandType') {
          return tag.fieldType !== type;
          // return tag.level !== level;
        } else if(type === 'functionType') {
          this.functionId = 0;
          return tag.fieldType !== type;
        } else if(type === 'productType') {
          if(fieldName === 'subCatId' || key === 'subCatId') {
            return tag.fieldType !== type;
          } else if(fieldName === 'thirdCatId' || key === 'thirdCatId') {
            return tag.level !== level && tag.level !== 4;
          }
          return tag.level !== level;
        } else if(type === 'industryType') {
          if(fieldName === 'industryFirstCatId') {
            return tag.fieldType !== type;
          }
          return tag.level !== level;
        }
      });
      this.tagList = [...tagList];
    },
    deleteTag(item) {
      this.deleteTagList(item);
      this.initValue(item.fieldType, item.fieldName || item.key);
      this.tagList.forEach(item => this[item.key] = item.value);
      this.getPDFList();
    },
    handleCurrentChange(value) {
      this.currentPage = value;
      this.getPDFList();
    },
    menuSwitch(b) {
      this.menuShow = b;
      // if(b) {
      //   this.pageSize = 20;
      // } else {
      //   this.pageSize = 24;
      // }
      this.pageSize = 24;
      this.currentPage = 1;
      this.getPDFList();
    },
    handleMenuItemClick(item) {
      this.tagList = [];
      let obj = {
        level: 1,
        key: 'thirdCatId',
        name: item.name,
        value: item.id,
        fieldType: 'productType'
      }
      this.initValue(obj.fieldType, obj.key);
      this.pushTagList(obj);
      this.thirdCatId = item.id;
      this.getPDFList();
    },
    getCategoryList() {
      GetCategoryList().then(data => {
        data.forEach((item, index) => {
          item.index = index + '';
          if(item.categroyList && item.categroyList.length) {
            item.categroyList.forEach((childItem, childIndex) => {
              childItem.index = index + '-' + childIndex;
              if(childItem.categroyList && item.categroyList.length) {
                childItem.categroyList.forEach((childSubItem, childSubIndex) => {
                  childSubItem.index = index + '-' + childIndex + '-' + childSubIndex;
                });
              }
            });
          }
        });
        this.menuDataList = [...data];
      })
    },
    getPDFList() {
        if (this.functionId) {
            GetCompanyListNew({
                'functionId': this.functionId,
                'pageNum': this.currentPage,
                'pageSize': this.pageSize
            }).then(data => {
                this.pdfList = [...data.list];
                this.total = data.total;
                data.queryData.forEach(item => {
                    let {data, name, type} = item;
                    data.length && data.forEach(dataItem => {
                        dataItem.fieldName = name;
                        dataItem.fieldType = type;
                    })
                });
                this.queryData = [...data.queryData];
            })
        } else {
            GetCompanyList({
                'brandId': this.brandId,
                'industryFirstCatId': this.industryFirstCatId,
                'industrySecordCatId': this.industrySecordCatId,
                'thirdCatId': this.thirdCatId,
                'fourCatId': this.fourCatId,
                'subCatId': this.subCatId,
                'key': this.key,
                'functionId': this.functionId,
                'pageNum': this.currentPage,
                'pageSize': this.pageSize
            }).then(data => {
                this.pdfList = [...data.list];
                this.total = data.total;
                data.queryData.forEach(item => {
                    let {data, name, type} = item;
                    data.length && data.forEach(dataItem => {
                        dataItem.fieldName = name;
                        dataItem.fieldType = type;
                    })
                });
                this.queryData = [...data.queryData];
            })
        }
    },
    pushTagList(item) {
      let tagList = [...this.tagList];
      tagList = tagList.filter(tag => (tag.level !== item.level));
      tagList.push(item);
      this[item.fieldName] = item.value;
      this.$nextTick(() => {
        tagList.sort((a, b) => {
          return a.level - b.level;
        });
        this.tagList = [...tagList];
      });
    },
    brandItemHandleClick(item) {
      item.key = 'brandId';
      item.level = 1;
      this.pushTagList(item);
      this.getPDFList();
    },
    functionItemHandleClick(item) {
      item.key = 'functionId';
      item.level = 10;
      this.pushTagList(item);
      this.getPDFList();
    },
    handleClickProductItem(item) {
      item.key = 'productType';
      item.level = 2;
      if(item.fieldName === 'subCatId') {
        item.level = 2;
      } else if(item.fieldName === 'thirdCatId') {
        item.level = 3;
      } else if(item.fieldName === 'fourCatId') {
        item.level = 4;
      }
      this.pushTagList(item);
      this.getPDFList();
    },
    PDFHandleClick(item) {
      // 打开品牌详情
      // window.open(window.location.origin + '#/Pdf/PdfDetail?uuid=' + item.uuid)
      let query =  {
        companyId: item.companyId,
        industrySecordCatName: item.industrySecordCatName,
        industrySecordCatId: item.industrySecordCatId
      };
      this.$router.push({
          path: '/Company/CompanyDetails',
          query
      });
    },
    handleClickIndustryItem(item) {
      item.key = 'industryType';
      item.level = 5;
      if(item.fieldName === 'industryFirstCatId') {
        item.level = 5;
      } else if(item.fieldName === 'industrySecordCatId') {
        item.level = 6;
      }
      this.pushTagList(item);
      this.getPDFList();
    },
  },
  mounted() {
    this.init();
  },
  computed: {
  },
}
</script>
<style scoped>
@import '../../style/pdf.css'
</style>
