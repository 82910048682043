<template>
  <div class="news-container">
    <div class="news-content">
      <div class="news-list">
        <div v-for="(item, index) in newsList"
             :key="index"
             class="news-item"
             @click="toNewsDetail(item)">
          <div class="news-image">
            <img :src="item.titleImage" :alt="item.title">
          </div>
          <div class="news-info">
            <h3 class="news-title">{{ item.title }}</h3>
            <div class="news-date">
              {{ formatDate(item.updateCreate) }}
            </div>
          </div>
        </div>
      </div>
      <paging
        v-if="total > pageSize"
        :pageSize="pageSize"
        :total="total"
        @handleCurrentChange="handleCurrentChange">
      </paging>
    </div>
  </div>
</template>

<script>
import { baseUrl } from '../../utils'
import paging from '../public/paging.vue'
import { getNewsInfoList,getNewsDetail } from '../../api/news/api'

export default {
  name: 'NewsInfo',
  components: {
    paging
  },
  data() {
    return {
      baseUrl,
      newsList: [],
      total: 0,
      currentPage: 1,
      pageSize: 12,
      category: 0,
      pcFlag: 1
    }
  },
  methods: {
    // 获取新闻列表
    getNewsList() {
      getNewsInfoList({
        // category: this.category,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        // pcFlag: this.pcFlag
      }).then(res => {
        console.log(res)
        this.newsList = res.list
        this.total = res.total
        console.log(this.newsList)
      })
    },
    // 格式化日期
    formatDate(timestamp) {
      const date = new Date(timestamp)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    },
    // 分页变化
    handleCurrentChange(page) {
      this.currentPage = page
      this.getNewsList()
    },
    // 跳转到新闻详情
    toNewsDetail(item) {
    //   this.$router.push({
    //     path: '/News/NewsDetail',
    //     query: {
    //       uuid: item.uuid
    //     }
    //   })
    // 这里调用详情接口获取的content在新页面打开
    getNewsDetail({
        uuid: item.uuid
      }).then(res => {
        this.newsDetail = res
        // 被微信限制，这里在新页面直接打开
        window.open(this.newsDetail.content, '_blank')
      })
    }
  },
  mounted() {
    this.getNewsList()
  }
}
</script>

<style scoped>
.news-container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
}

.news-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.news-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.news-item {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.news-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.news-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.news-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.news-item:hover .news-image img {
  transform: scale(1.05);
}

.news-info {
  padding: 15px;
}

.news-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  line-height: 1.4;
  height: 44px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-date {
  font-size: 14px;
  color: #666;
}

/* 响应式布局 */
@media (max-width: 1200px) {
  .news-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .news-list {
    grid-template-columns: 1fr;
  }
}
</style>
