<template>
    <div>
        <div v-for="(lineItem) in (Math.ceil(functionShowRoomList.length / 4) > 2 && functionShowRoomMore ? Math.ceil(functionShowRoomList.length / 4): 2)" :key="lineItem" class="type_img_div clearfix">
            <div v-for="(item, index) in functionShowRoomList.slice((lineItem - 1) * 4, lineItem * 4)" :key="index"  @click="toCompany(item, 'functionId')" class="type_img_block">
                <div class="type_img_block_content">
                    <img :class="['logo-img', item.brandImageUrl ? '' : 'hide']" :src="baseUrl + item.brandImageUrl" style={} >
                    <img :src="baseUrl + item.imageUrl">
                </div>
                <div class="type_img_block_title">
                    <span>{{item.name}}</span>
                    <img class="active_bg" src="../assets/other/more.png">
                    <img class="default_bg" src="../assets/other/more2.png">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { baseUrl } from '../utils'
import {GetRoomList} from "@/api/home/api";
export default {
  name: 'NewProductRelease',
  data() {
    return {
      menuShow: true,
      functionShowRoomList: [],
      functionShowRoomMore: true,
      baseUrl
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // eslint-disable-next-line no-console
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // eslint-disable-next-line no-console
      console.log(key, keyPath);
    },
    menuSwitch() {
      this.menuShow = !this.menuShow
    },
      toCompany(item, fieldName) {
          let query =  {
              name: item.name,
              searching: 1
          };
          if(fieldName === 'industry') {
              query.level = 5;
              let fileds = Object.keys(item).filter(filedsItem => filedsItem.startsWith(fieldName));
              query.type = fileds[0];
              if(query.type === 'industrySecordCatId') {
                  query.level = 6;
              }
              query.fieldName = 'industryType';
              query.fieldId = item[fileds[0]];
          } else if(fieldName === 'product') {
              query.level = 2;
              query.fieldName = 'productType';
              let fileds = Object.keys(item).filter(filedsItem => filedsItem.endsWith('CatId'));
              query.type = fileds[0];
              if(query.type === 'subCatId') {
                  query.level = 2;
              } else if(query.type === 'thirdCatId') {
                  query.level = 3;
              } else if(query.type === 'fourCatId') {
                  query.level = 4;
              }
              query.fieldId = item[fileds[0]];
          } else if(fieldName === 'brandId') {
              query.level = 1;
              query.fieldName = 'brandType';
              query.type = 'brandId';
              query.fieldId = item.brandId;
          }  else if(fieldName === 'functionId') {
              query.level = 10;
              query.fieldName = 'functionType';
              query.type = 'functionId';
              query.fieldId = item.functionId;
          }
          try {
              this.$router.push({
                  path: '/Company/CompanyList',
                  query
              });
              console.log('Navigation triggered');
          } catch (err) {
              console.error('Navigation error:', err);
              this.$message.error('页面跳转失败，请重试');
          }
      },
      getRoomList() {
          GetRoomList().then(data => {
              let { brandShowRoomList, functionShowRoomList, industryShowRoomList } = data;
              this.functionShowRoomList = [...functionShowRoomList];
          })
      },
  },
  mounted() {
      this.getRoomList();
  },
  computed: {
  },
}
</script>
<style scoped>

.type_img_div {
    background-color: #e8e8e8;
    display: flex;
    padding: 10px 10px 0;
    width: 100%;
    box-sizing: border-box;
}
.type_img_block {
    /* float: left; */
    /* flex: 1; */
    /* padding: 10px 10px 0px 10px; */
    padding: 0 24px;
    margin: 0 2% 10px 0;
    /* width: 424px; */
    height: 306px;
    background-color: #fff;
    border-bottom: 7px solid #c2c2c2;
    box-sizing: border-box;
    width: 23.5%;
}
.type_img_block:nth-last-child(1) {
    margin-right: 0;
}
.type_img_block_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.type_img_block_content img {
    width: 100%;
    height: 200px;
    border: 1px solid #dedede;
}
.type_img_block_content .logo-img {
    width: 108px;
    height: 40px;
    margin-left: auto;
    border: none;
}
.type_img_block_content .logo-img.hide {
    opacity: 0;
}
.type_img_block_title div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 425px;
    font-size: 18px;
    color: #b1b1b1;
}

.type_img_block_title img{
    position: absolute;
    right: -14px;
    bottom: 6px;
    width: 32px;
    height: 20px;
    display: none;
}
.type_img_block_title .default_bg {
    display: block;
}
.type_img_block_title {
    /* display: flex; */
    /* margin: 10px 5px; */
    height: 64px;
    line-height: 64px;
    position: relative;
    font-family: PingFangSC-Regular, PingFang SC;
    text-align: center;
    font-size: 18px;
}
.type_img_block:hover {
    border-bottom: 7px solid var(--main-color);
}
.type_img_block:hover .default_bg {
    display: none;
}
.type_img_block:hover .active_bg {
    display: block;
}
</style>
