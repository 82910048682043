import Vue from 'vue'
import Router from 'vue-router'
import Index from '../components/Index'
import Pdf from '../components/Pdf/index.vue'
import PdfSearch from '../components/PdfSearch/index.vue'
import Login from '../components/Login.vue'
import Signup from '../components/Signup.vue'
import Person from '../components/Person.vue'
import ForgetPwd from '../components/ForgetPwd.vue'
import Blueprint2D3D from '../components/Blueprint2D3D/index.vue'
import NewProductRelease from '../components/NewProductRelease'
import ProductVideo from '../components/ProductVideo'
import ProductPic from '../components/ProductPic'
import PdfDetail from '../components/PdfDetail'
import CanvasDetail from '../components/CanvasDetail'
import ProductDiscussion from '../components/ProductDiscussion/ProductDiscussion'
import EntryPlatform from '../components/EntryPlatform'
import AboutUs from '../components/AboutUs'
import ExhibitionHall from "@/components/exhibitionHall";
import ExhibitionHallDetails from "@/components/exhibitionHallDetails";
import CompanyList from "../components/Company/CompanyList.vue";
import CompanyDetails from "../components/Company/CompanyDetails.vue";
import NewsInfo from "../components/NewsInfo/newsInfo.vue";
import NewsDetail from '@/components/NewsInfo/NewsDetail'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index
        },
        {
            path: '/AboutUs',
            name: 'AboutUs',
            component: AboutUs
        },
        {
            path: '/Pdf',
            name: 'Pdf',
            component: Pdf
        },
        {
            path: '/Pdf/PdfSearch',
            name: 'PdfSearch',
            component: PdfSearch
        },
        {
            path: '/Login',
            name: 'Login',
            component: Login
        },
        {
            path: '/Signup',
            name: 'Signup',
            component: Signup
        },
        {
            path: '/ForgetPwd',
            name: 'ForgetPwd',
            component: ForgetPwd
        },
        {
            path: '/Blueprint2D3D',
            name: 'Blueprint2D3D',
            component: Blueprint2D3D
        },
        {
            path: '/Person',
            name: 'Person',
            component: Person
        },
        {
            path: '/NewProductRelease',
            name: 'NewProductRelease',
            component: NewProductRelease
        },
        {
            path: '/ProductVideo',
            name: 'ProductVideo',
            component: ProductVideo
        },
        {
            path: '/Pdf/PdfDetail',
            name: 'PdfDetail',
            component: PdfDetail
        },
        {
            path: '/Canvas/CanvasDetail',
            name: 'CanvasDetail',
            component: CanvasDetail
        },
        {
            path: '/ProductDiscussion',
            name: 'ProductDiscussion',
            component: ProductDiscussion
        },
        {
            path: '/EntryPlatform',
            name: 'EntryPlatform',
            component: EntryPlatform
        },
        {
            path: '/ProductPic',
            name: 'ProductPic',
            component: ProductPic
        },
        {
            path: '/ExhibitionHall',
            name: 'ExhibitionHall',
            component: ExhibitionHall
        },
        {
            path: '/ExhibitionHallDetails',
            name: 'ExhibitionHallDetails',
            component: ExhibitionHallDetails
        },
        {
            path: '/Company/CompanyList',
            name: 'CompanyList',
            component: CompanyList
        },
        {
            path: '/Company/CompanyDetails',
            name: 'CompanyDetails',
            component: CompanyDetails
        },
        {
            path: '/News/newsInfo',
            name: 'NewsInfo',
            component: NewsInfo
          },
        {
            path: '/News/NewsDetail',
            name: 'NewsDetail',
            component: NewsDetail
        }
    ]
})
